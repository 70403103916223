import { allShapesSelector } from '@/store/recoil/shapes';
import { Connection } from '@modules/common/types/connections';
import {
  isAngledHighwayShape,
  isAreaShape,
  isHighwayShape,
  isPositionShape,
  isProcessAreaTwoEp,
} from '@modules/common/types/guards';
import {
  filterRelatedConnections,
  findRelatedConnection,
} from '@modules/connections/common/helpers';
import {
  findConnectionsPositions,
  getDefaultInOutChecked,
} from '@modules/connections/connections/helpers/connections';
import {
  allConnectionsSelector,
  updateConnectionsSelector,
} from '@modules/connections/connections/store';
import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';
import { useUpdateCrossings } from '../../crossings';
import { ShapeType } from '@/modules/common/types/shapes';

export const useUpdateConnection = () => {
  const { updateCrossings } = useUpdateCrossings();

  // regular connections are generated here
  const updateConnections = useRecoilCallback(
    ({ set, snapshot }) =>
      async (shapeIds: string[]) => {
        const [allConnections, allShapes] = await Promise.all([
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(allShapesSelector),
        ]);

        const connectionIdsToRemove = new Set<string>();
        const connectionsToAddOrUpdate: Connection[] = [];
        const connectableShapes = allShapes.filter(
          (item) =>
            isAreaShape(item) ||
            isPositionShape(item) ||
            isHighwayShape(item) ||
            isAngledHighwayShape(item) ||
            isProcessAreaTwoEp(item),
        );

        connectableShapes
          .filter((fromShape) => shapeIds.includes(fromShape.id))
          .forEach((fromShape) => {
            const existingShapeConnections = filterRelatedConnections(allConnections, [
              fromShape.id,
            ]);
            const newAndUpdatedConnections = findConnectionsPositions(
              fromShape,
              connectableShapes,
            ).map((connectionPosition) => {
              const existingConnection = findRelatedConnection(
                existingShapeConnections,
                connectionPosition.to,
                connectionPosition.from,
              );

              if (existingConnection) {
                /*
                    A connection consists of two shapes (e.g., a Highway connecting to an <Area>). 
                    When the initial connection is created, the IDs are stored in the 'existingConnection' object. 
                    This object tracks the connection direction using 'inChecked' and 'outChecked' (e.g., from Highway to <Area>).

                    However, if the <Area> is later moved while still connected, the roles of 'from' and 'to' (i.e., 'checkedIn' and 'checkedOut') are reversed. 
                    This code ensures the connection reflects these changes correctly to avoid incorrect directionality.
                  */
                const shouldFlip = existingConnection.to !== connectionPosition.to;

                return {
                  id: existingConnection.id,
                  usePivots: existingConnection.usePivots,
                  from: shouldFlip ? existingConnection.to : existingConnection.from,
                  to: shouldFlip ? existingConnection.from : existingConnection.to,
                  position: {
                    x: Math.round(connectionPosition.position.x),
                    y: Math.round(connectionPosition.position.y),
                  },
                  inChecked: shouldFlip
                    ? existingConnection.outChecked
                    : existingConnection.inChecked,
                  outChecked: shouldFlip
                    ? existingConnection.inChecked
                    : existingConnection.outChecked,
                  rot: connectionPosition.rot,
                };
              }

              const toShape = connectableShapes.find((s) => s.id === connectionPosition.to);
              return {
                id: uuid(),
                from: connectionPosition.from,
                to: connectionPosition.to,
                ...getDefaultInOutChecked(fromShape.type, toShape?.type ?? ShapeType.HIGHWAY),
                usePivots: false,
                position: {
                  x: Math.round(connectionPosition.position.x),
                  y: Math.round(connectionPosition.position.y),
                },
                rot: connectionPosition.rot,
              };
            });

            connectionsToAddOrUpdate.push(...newAndUpdatedConnections);

            const connectionsIdsToAddOrUpdate = new Set(
              connectionsToAddOrUpdate.map((item) => item.id),
            );

            existingShapeConnections
              .filter((item) => !connectionsIdsToAddOrUpdate.has(item.id))
              .forEach((item) => connectionIdsToRemove.add(item.id));
          });

        set(updateConnectionsSelector, {
          connections: connectionsToAddOrUpdate,
          connectionIdsToRemove,
        });
      },
    [updateCrossings],
  );

  return {
    updateConnections,
  };
};